export default [
    {
        to: {name: 'dashboard.index'},
        icon: 'mdi-home',
        text: 'Dashboard',
    },
    {
        to: {name: 'dashboard.billing'},
        icon: 'mdi-credit-card',
        text: 'Billing',
    },
    {
        to: {name: 'dashboard.billing.select-plan'},
        icon: 'mdi-credit-card',
        text: 'Select Plan',
    },
    {
        to: {name: 'admin.stores.index'},
        icon: 'mdi-web',
        text: 'Stores',
    },
    {
        to: {name: 'admin.template.index'},
        icon: 'mdi-layers-triple-outline',
        text: 'Templates',
    },
]
